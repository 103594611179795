<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Approved suppliers') }}
    </p>
    <section class="content-margin">
      <DRFilter @changeBusiness="changeBusiness"></DRFilter>
      <div class="d-flex justify-content-between column-tb-blocks px-7">
        <div class="column-tb">
          <column-table :table_title="$t('Check All')" :table_data.sync="suppliersData" :view-mode="!canBeEdited" />
        </div>
      </div>
      <div class="mt-20 px-7 mb-15">
        <button v-if="canBeEdited" class="primary-btn btn-limit-width" @click="handleUpdateSuppliers">
          {{ $t("Save") }}
        </button>
      </div>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ColumnTable from '../../../components/ColumnTable';
import DRFilter from "../../../components/management/DRFilter.vue";
export default {
  name: 'DRSuppliers',
  components: {
    ColumnTable,
    DRFilter
  },
  data() {
    return {
      businessID: "",
      distributeID: "",
      canBeEdited: false,
    }
  },
  computed: {
    ...mapState({
      businessData: (state) => state.seller_tourism_percentage.businessData,
      suppliersData: (state) => state.seller_tourism_percentage.suppliersData,
      loading: (state) => state.seller_tourism_percentage.loading,
    }),
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getSuppliers: 'getSuppliers',
      updateSuppliers: 'updateSuppliers',
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      const selectedBusiness = this.businessData.filter(function (el) {
        return el.id == data.business_id;
      })[0];
      if (selectedBusiness) {
        this.canBeEdited = selectedBusiness.can_be_edited;
      }
      await this.getSuppliers({id: data.business_id});
    },
    async handleUpdateSuppliers() {
      let suppliers_list = [];
      this.suppliersData.forEach(item => {
        suppliers_list.push({
          id: item.supplier_id,
          is_authorized: item.is_authorized
        })
      });

      const params = {
        business_id: this.businessID,
        suppliers_list: suppliers_list
      }
      await this.updateSuppliers({
        params: params
      });
      this.changeBusiness({
        distribute_id: this.distributeID,
        business_id: this.businessID
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.column-tb {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .column-tb-blocks {
    flex-wrap: wrap;
    .column-tb {
      width: 100%;
    }
  }
}
</style>